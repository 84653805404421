import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

/**
 * AlertsService
 * 
 * This service provides a centralized and consistent way to display various types of alerts in the application
 * using SweetAlert2. It allows developers to show alerts for success, error, loading, informational messages, warnings,
 * confirmation prompts, and more. The service also includes default settings for each type of alert, which can be
 * customized as needed.
 * 
 * The service is designed to maintain consistency across the application, reduce redundancy, and simplify the process 
 * of managing alerts by centralizing alert logic in one place.
 */

@Injectable({
    providedIn: 'root',
})
export class AlertsService {

    private defaultOptions: SweetAlertOptions = {
        confirmButtonColor: '#3F51B5',
        allowOutsideClick: false,
    };

    private successDefaults: SweetAlertOptions = {
        title: 'Успех',
        icon: 'success',
        confirmButtonColor: '#3F51B5',
        allowOutsideClick: false,
    };

    private errorDefaults: SweetAlertOptions = {
        title: 'Грешка',
        icon: 'error',
        confirmButtonColor: 'blue',
        allowOutsideClick: false,
        showConfirmButton: true,
    };

    private loadingDefaults: SweetAlertOptions = {
        title: 'Зареждане...',
        allowOutsideClick: false,
        showConfirmButton: false,
    };

    private infoDefaults: SweetAlertOptions = {
        title: 'Информация',
        icon: 'info',
        confirmButtonColor: '#3F51B5',
    };

    private warningDefaults: SweetAlertOptions = {
        title: 'Внимание',
        icon: 'warning',
        confirmButtonColor: '#FFA500',
    };

    private questionDefaults: SweetAlertOptions = {
        title: 'Сигурни ли сте?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Не',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
    };

    private confirmationDefaults: SweetAlertOptions = {
        title: 'Потвърждавате ли?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Да, потвърждавам',
        cancelButtonText: 'Откажи',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
    };

    public showAlert(options: SweetAlertOptions): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.defaultOptions, ...options };
        return Swal.fire(mergedOptions);
    }

    public showSuccess(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.successDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showLoading(options: Partial<SweetAlertOptions> = {}): void {
        const mergedOptions = { ...this.loadingDefaults, ...options };
        Swal.fire(mergedOptions);
        Swal.showLoading();
    }

    public showInfo(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.infoDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showWarning(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.warningDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showQuestion(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.questionDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showConfirmation(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.confirmationDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showTimedAlert(options: Partial<SweetAlertOptions> = {}, timer: number = 2000): Promise<SweetAlertResult<any>> {
        const mergedOptions = { ...this.defaultOptions, ...options, timer, timerProgressBar: true };
        return Swal.fire(mergedOptions);
    }

    public showInputAlert(options: Partial<SweetAlertOptions> = {}): Promise<SweetAlertResult<any>> {
        const inputDefaults: SweetAlertOptions = {
            title: 'Въведете информация',
            input: 'text',
            confirmButtonText: 'Изпрати',
            cancelButtonText: 'Отказ',
            showCancelButton: true,
            confirmButtonColor: '#3F51B5',
        };
        const mergedOptions = { ...inputDefaults, ...options };
        return Swal.fire(mergedOptions);
    }

    public showError(error: HttpErrorResponse | string, customOptions?: Partial<SweetAlertOptions>): Promise<SweetAlertResult<any>> {
        let errorMessage: string;
    
        if (typeof error === 'string') {
            errorMessage = error;
        } else {
            errorMessage = error.error?.message ?? error.statusText ?? 'Възникна неизвестна грешка.';
        }
    
        // Default error options
        const defaultOptions: SweetAlertOptions = {
            ...this.errorDefaults,
            html: errorMessage, // Use html instead of text to allow formatting
        };
    
        // Merge any custom options provided with the defaults
        const mergedOptions = { ...defaultOptions, ...customOptions };
    
        return Swal.fire(mergedOptions);
    }
}
