import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, throwError } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { AuthData } from './auth-data.model';
import { AlertsService } from '../services/alerts.service';

const BACKEND_URL = environment.apiUrl + '/';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticated = false;
  private token: any;
  private tokenTimer: any;
  private userId: any;
  private role: any;
  private authStatusListener = new Subject<boolean>();
  private intendedRoute: string | null = null;
  private dataSubject = new Subject<any>();

  public postSaved = new BehaviorSubject<boolean>(false);
  public sessionExpired: boolean = false;
  public data$ = this.dataSubject.asObservable();

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private readonly alertsService: AlertsService) { }

  public getToken() {
    return this.token;
  }

  public getIsAuth() {
    return this.isAuthenticated;
  }

  public getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  public setIntendedRoute(url: string): void {
    this.intendedRoute = url;
  }

  public getIntendedRoute(): string | null {
    return this.intendedRoute;
  }

  public clearIntendedRoute(): void {
    this.intendedRoute = null;
  }

  public createUser(email: string, password: string, name: string, family: string, mobileNumber: number, region: string, workerSpecialty: string, domain: string, role: string): Observable<any> {
    this.alertsService.showLoading({
      title: `Създаване на потребител с имейл ${email}...`,
    });

    const authData = {
      email: email,
      password: password,
      name: `${name} ${family}`,
      mobileNumber: mobileNumber,
      region: region,
      workerSpecialty: workerSpecialty,
      env: domain,
      role: role
    };

    return this.http.post(`${BACKEND_URL}signup`, authData).pipe(
      tap(() => {
        this.alertsService.showSuccess({
          title: 'Успешно създаване',
          html: `Потребителят <strong>${email}</strong> беше успешно създаден. Моля, проверете вашата електронна поща, за да потвърдите регистрацията.`,
        });
        this.router.navigate(['/auth/login']);
      }),
      catchError(error => {
        this.authStatusListener.next(false);
        this.alertsService.showError(error);
        return throwError(() => error);
      })
    );
  }

  public verifyEmail(token: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/verify-email?token=${token}`).pipe(
      map(res => {
        this.alertsService.showSuccess({
          title: 'Успешно потвърден емайл!',
        });
        this.router.navigate(['/auth/login']);
        return res;
      }),
      catchError(err => this.alertsService.showError(err))
    );
  }

  public resendEmailVerification(email: string, domain: string): void {
    const data = {
      env: domain,
      email: email
    };

    this.http.post(`${BACKEND_URL}resend-verification-email`, data).subscribe(
      () => {
        this.alertsService.showSuccess({
          title: 'Емайл изпратен успешно.',
          html: `Моля проверете <strong>${email}</strong>, за да потвърдите емайла си.`,
        });
      },
      error => {
        this.alertsService.showError(error);
      }
    );
  }


  public login(email: string, password: string): Observable<any> {
    const authData: AuthData = { email: email, password: password };
    return this.http
      .post<{ token: string, expiresIn: number, userId: string, role: string, emailVerified: boolean }>(
        `${BACKEND_URL}login`, authData
      )
      .pipe(
        tap(response => {
          const token = response.token;
          this.token = token;
          if (token) {
            const expiresInDuration = response.expiresIn;
            this.setAuthTimer(expiresInDuration);
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.role = response.role;
            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
            this.saveAuthData(token, expirationDate, this.userId, this.role);
            this.dataSubject.next(response.role);
          }
        }),
        catchError(error => {
          this.authStatusListener.next(false);
          this.alertsService.showError(error);
          return throwError(() => error);
        })
      );
  }

  public resetPassword(email: string, domain: string): void {
    const data = {
      env: domain,
      email: email
    };

    this.http.post(`${BACKEND_URL}reset-password`, data).subscribe(
      () => {
        this.router.navigate(['/auth/login']);
        this.alertsService.showSuccess({
          title: "Емайл изпратен успешно.",
          html: `Моля проверете <strong>${email}</strong>, за да смените паролата си.`,
        });
      },
      error => {
        this.alertsService.showError(error);
      }
    );
  }

  public getNewPasswordTokenData(token): Observable<any> {
    return this.http.get(environment.apiUrl + "/new-password/" + token).pipe(map(res => { return res }))
  }

  public postNewPassword(data: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/new-password`, data).pipe(
      map(res => {
        return res;
      }),
      catchError(err => {
        this.alertsService.showError(err);
        return throwError(() => err);
      })
    );
  }

  //This is needed so we do not lose our authentication on refresh
  public autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.role = authInformation.role
      this.setAuthTimer(expiresIn / 1000)
      this.authStatusListener.next(true);
    }
  }

  public async logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    sessionStorage.clear()
    this.clearAuthData();
    this.userId = null;
    // Set sessionExpired to true before navigating
    this.sessionExpired = true;
    // Wait for the navigation to complete
    await this.router.navigate(['/auth/login']);
    // Reset sessionExpired to false after navigation is done
    this.sessionExpired = false;
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.dialog.closeAll();
      this.logout();
      this.sessionExpiredAlert();
    }, duration * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, role: string) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString())
    localStorage.setItem("userId", userId)
    localStorage.setItem("role", role)
  }

  private clearAuthData() {
    localStorage.removeItem("token")
    localStorage.removeItem("expiration")
    localStorage.removeItem("userId")
    localStorage.removeItem("role")
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId")
    const role = localStorage.getItem("role")
    if (!token || !expirationDate) {
      return null;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      role: role
    }
  }

  private sessionExpiredAlert() {
    Swal.fire({
      title: "Сесията ви изтече",
      icon: 'info',
      html: "Моля влезте отново",
      confirmButtonColor: '#3F51B5',
      allowOutsideClick: false,
    });
  }
}